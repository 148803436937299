.margin-container {
    margin-top: 100vh;
    padding-bottom: 50px;
}

.container {
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    width: 100%;
}