.social-buttons-container {
    display: grid;
    grid-template-columns: repeat(7, 1fr);
    justify-content: space-between;
    align-items: center;
    gap: 10px;
}

.vertical-line {
    height: 30px;
    width: 2px;
    margin: auto;
    background-color: #ffffff;
}

@media only screen and (max-width: 450px) {
    .vertical-line {
        display: none;
    }
    .social-buttons-container {
        grid-template-columns: repeat(2, 1fr);
        justify-items: stretch;
    }
}