.App {
  color: var(--theme-primary);
}


@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

/* width */
::-webkit-scrollbar {
  width: 12px;
}

/* Track */
::-webkit-scrollbar-track {
  background-color: rgba(0, 0, 0, 1);
  opacity: 0.0;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: var(--theme-accent);
  background: linear-gradient(to bottom, var(--theme-accent), var(--theme-accentDark));
}

a, a:link, a:visited {
  color: var(--theme-primary);
}

  a:active, a:hover {
  color: var(--theme-accent);
}